import router from '../router'

const actions = {
  async completeLogin({ commit }, user) {
    if (user !== undefined || user !== null) {
      commit('updateUser', user)
      commit('updateIsAuthenticated', true)
    } else {
      console.log('DEBUG: couldnt login the user.')
      router.push('/login').catch((err) => {
        console.log(err)
      })
    }
    console.log('DEBUG: Store login complete.')
  },

  setIsProgressing({ commit }, value) {
    commit('IsProgressing', value)
  },

  setLoggedInUser({ commit }, value) {
    commit('updateUser', value)
  },

  setLoggedInLocation({ commit }, value) {
    commit('updateLocation', value)
  },

  setGlobalMessage({ commit }, value) {
    commit('globalMessage', value)
  },

  setApplicationVersion({ commit }, value) {
    commit('updateApplicationVersion', value)
  },
}

export default actions
