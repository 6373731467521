const state = {
    loggedInUser            : null,
    currentLocation         : {},
    isAuthenticated         : false,
    isProgressing           : false,
    globalMessage           : "",
    applicationVersion      : "",
}

export default state
