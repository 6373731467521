const mutations = {

  updateIsAuthenticated(state, value) {
    state.isAuthenticated = value;
  },

  updateUser(state, value) {
    state.loggedInUser = value;
  },

  updateLocation(state, value) {
    state.currentLocation = value;
  },

  IsProgressing(state, value) {
    state.isProgressing = value;
  },

  globalMessage(state, value) {
    state.globalMessage = value;
  },

  updateApplicationVersion(state, value) {
    state.applicationVersion = value;
  }
}

export default mutations

