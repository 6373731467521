const getters = {
  loggedInUser : state => {
    return state.loggedInUser;
  },
  loggedInUserToken : state => {
    if (state.loggedInUser){
      return state.loggedInUser.token
    }
    return ""
  },
  currentLocation : state => {
    return state.currentLocation;
  }
}

export default getters
